.App {
  text-align: center;
}
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f8;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Ubuntu", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: "Ubuntu", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

:root {
  font-family: "Ubuntu", sans-serif;
  --amplify-primary-color: #32b8a1;
  --amplify-primary-tint: #14785c;
  --amplify-background-color: white;
  --amplify-primary-shade: #08553f;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

i.icon.hidden {
  display: none;
}

#playButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: scale(5, 5);
}

.sonify-logo {
  position: absolute;
  top: 2vh;
  left: 2vw;
  max-width: 55%;
  z-index: 10000;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes flash {
  0% {
    background-position: 0% 0%; /* Initial background position */
  }
  50% {
    background-position: 0% 100%; /* Flash background position */
  }
  100% {
    background-position: 0% 0%; /* Back to initial background position */
  }
}

.flash-element {
  background: linear-gradient(
    to bottom,
    #32b8a1,
    rgb(226, 219, 122)
  ); /* Gradient background */
  background-size: 100% 200%; /* Adjust the size to cover the entire element */
  animation: flash 1s 5s; /* Adjust the duration as needed */
}

.logobacking {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-radius: 50% 50%;
  width: 160px;
  height: 160px;
}

.play {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 64px;
  height: 64px;
  fill: black;
}

.stopped {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 44px;
  height: 44px;
  fill: #9e9e9e;
}

.logo {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 128px;
  height: 128px;
}
.logo path {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
  animation: dash 1.5s alternate infinite;
  stroke: #9e9e9e;
  stroke: width 1.2px;
  fill: white;
}

.logo g {
  animation: fill-in 1.5s 1.5s alternate infinite;
  fill-opacity: 1;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

.hidden {
  display: none !important;
}

.rightMenu {
  background-color: #f1f1f1;
  border-left: 1px #d1d1d1 solid;
  width: 50px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* align-items: right; */
  /* padding-top: 10px; */
  /* align-content:right; */

  /* text-align: center; */
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  align-content: flex-start; */
}

.rightMenuItems {
  padding-top: 30px;
  z-index: 400;
  width: 58px;
  height: 58px;

  /* padding-top: 66px;
  position: absolute;
  right: 0; */
  /* top: 2vh; */
}

.user {
  padding-left: 12px;
  position: fixed;
  bottom: 30px;
}

.configurePanel {
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  right: 50px;
  position: fixed;
  height: 100vh;
  width: 340px;
  z-index: 700;
  overflow-y: scroll;
  scrollbar-color: black black !important;
}
.configurePanel::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
}

.configureTitle {
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
  padding-left: 11px;
  height: 54px;
  background: white;
  border-bottom: 1px solid darkgray;
  text-align: center;
}

.menuItemTitle {
  font-size: 16px;
  padding: 14px 0 0 14px;
  height: 48px;
  background: rgba(255, 255, 255, 0.8);
}

.moreAbout {
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 14px;
}

.slider input {
  height: 1px;
  outline: none;
  max-width: 94%;
}

input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#666, #666);
  background-size: 98%;
  background-repeat: no-repeat;
  margin-bottom: 16px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 4px #32b8a1 solid;
  background: white;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #32b8a1;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #32b8a1;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.sliderTitle {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  float: left;
}

.sliderTitleValue {
  font-size: 14px;
  padding-top: 12px;
  float: right;
  margin-right: 10px;
}

.items {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  /* padding: 15px; */
}

.items .item {
  padding: 12px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  min-height: 48px;
}

.items .item .item-title {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  padding-right: 80px;
  padding-left: 30px;
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.items .item .item-title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-image: url("./assets/icons/accordianPlus.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.items .item .item-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  /* transition: all 0.1s ease-out; */
}

.items .item.open .item-title {
  margin-bottom: 15px;
}

.items .item.open .item-title::after {
  background-image: url("./assets/icons/accordianMinus.svg");
}

.items .item.open .item-answer {
  max-height: 1000px;
  opacity: 1;
}

.Nexus_settings {
  background-image: url("./assets/icons/nexusSettings.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Height_Distribution {
  background-image: url("./assets/icons/baffleHeights.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Grid_lengths {
  background-image: url("./assets/icons/gridLengths.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Canopy_Gaps {
  background-image: url("./assets/icons/canopyGaps.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Border {
  background-image: url("./assets/icons/borders.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Look {
  background-image: url("./assets/icons/lookIcon.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Island_size {
  background-image: url("./assets/icons/islandSize.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Canopy_shape {
  background-image: url("./assets/icons/canopyShape.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Alignment {
  background-image: url("./assets/icons/alignment.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Spacing {
  background-image: url("./assets/icons/spacing.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Orientation {
  background-image: url("./assets/icons/orientation.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Height {
  background-image: url("./assets/icons/height.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Lighting {
  background-image: url("./assets/icons/lighting.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Grid_colour {
  background-image: url("./assets/icons/gridColour.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Colour_palette {
  background-image: url("./assets/icons/gridColour.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.Grid_colours {
  background-image: url("./assets/icons/gridColour.svg");
  background-repeat: no-repeat;
  height: 22px;
}

.item.open .item-title {
  filter: invert(67%) sepia(92%) saturate(311%) hue-rotate(116deg)
    brightness(93%) contrast(94%);
}

.lookContainer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  flex-wrap: wrap;
}

.lookCategoryContainer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.ceilingContainer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;

  align-items: center;

  flex-wrap: wrap;
}
.canopyContainer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 8px;
}

.lookSelection {
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
}

.con-box-button {
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  height: 40px;
  width: 47%;
  border-radius: 2px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.con-box-button:hover {
  color: #fff;
  background-color: #3cdbc0;
}

.con-page-button {
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  height: 32px;
  gap: 1rem;
  border-radius: 2px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: 0.5s;
  min-width: 100%;
}

.con-page-button:hover {
  color: #fff;
  background-color: #3cdbc0;
}

.button {
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;
  border-radius: 2px;
  height: 32px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  width: 70%;
  -ms-transform: translateX(25%);
  transform: translateX(25%);
  cursor: pointer;
  transition: 0.5s;
}

.button:hover {
  color: #fff;
  background-color: #3cdbc0;
}

.confirm-button {
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  border-radius: 2px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  -ms-transform: translateX(25%);
  transform: translateX(25%);
  cursor: pointer;
  transition: 0.5s;
}

.button span {
  position: relative;
}
.button:hover span::after {
  width: 100%;
  background-color: #101820;
  max-width: 10000px;
}
.button span::after {
  content: "";
  background-color: #101820;
  width: 0;
  height: 2px;
  -webkit-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.button:focus {
  color: #fff;
  background-color: #3cdbc0;
}

.modal-button.back {
  border: none;
  transform: scale(1);
}

.button.back::before {
  display: inline-block;
  align-self: center;
  scale: -1 1;
  content: "\00A0 »";
  font-size: 18px;
  left: -14px;
}

.button.back:hover {
  color: #fff;
  background-color: #3cdbc0;
}

button:disabled {
  pointer-events: none;
  border-color: rgba(100, 100, 100, 0.4);
}

select.dropdown {
  color: black;
  cursor: pointer;
  border: 2px solid #3cdbc0;
  border-radius: 2px;
  padding: 5px;
  font-family: "Ubuntu", sans-serif;
}

.flexContainer {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: nowrap; */
}

.multiColour {
  width: 80%;
}

.height-slider-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.images {
  display: block;
  align-items: center;
}

.img-wrap {
  padding: 4px;
  border: 2px solid transparent;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}
.img-wrap-invis {
  padding: 4px;
  border: 2px solid transparent;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
}
.img-wrap:hover .tick {
  opacity: 1;
}
/* .img-wrap:hover {
  border-color: #5fe1cb;
} */

.img-inner {
  display: flex;
}
.img-inner.scene {
  display: flex;
  width: 200px;
}
.tick {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: url(./assets/icons/tick.svg) no-repeat center center;
  z-index: 3000;
}
.tick-small {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: url(./assets/icons/ticksmall.svg) no-repeat center center;
}
.colour-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.colour-button {
  display: inline-block;
  flex: 1;
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;
  border-radius: 2px;
  height: 32px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
}
.colour-button:hover {
  color: #fff;
  background-color: #3cdbc0;
}
.colour-selected {
  color: #fff;
  background-color: #3cdbc0;
}
.modal-button:disabled {
  pointer-events: none;
  border-color: rgba(100, 100, 100, 0.4);
}

.raft-delete-confirm {
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  border: 2px solid #3cdbc0;
  border-radius: 2px;
  padding-top: 4px;
  font-weight: 700;
  transform: 0.5s;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.raft-delete-confirm:first-child {
  margin-right: 6px;
}

.modal-subtext {
  color: grey;
  font-size: 12px;
  text-align: center;
  max-width: 75%;
}

.revit-download-section:hover {
  cursor: pointer;
}

.download-modal-button {
  transition: 0.5s;
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;
  border-radius: 2px;
  height: 32px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  width: 30%;
  cursor: pointer;
}

@keyframes referenceAnimation {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    trasnform: scale(1);
    opacity: 1;
  }
}
