.camera-views {
  flex-direction: row;
  display: flex;
  flex: 1 1 auto;
}
.camera-controls {
  display: flex;
  flex-direction: column;
}
.camera-icon {
  height: 32px;
  width: 32px;
  margin-right: 10px;
  margin-left: 10px;
  transition: all 0.3s;
  cursor: pointer;
}
.camera-icon:hover {
  transform: scale(1.2);
  fill: #5fe1cb;
}
.scene-container {
  left: 0;
  bottom: 0;
  position: absolute;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 75%;
}
.main-flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.scene-button-row {
  display: flex;
  flex-direction: row;
}
.control-display {
  flex: 1 1 auto;
  margin-bottom: 20px;
}

/* Camera CSS */
.camera-angle-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.light-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 20px;
}
