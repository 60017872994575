.tableCell {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.tableHead {
  border-bottom: 1px solid black;
}
.email:hover {
  text-decoration: underline;
}
