.modalMain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  overflow: auto;
  padding: 0;
  margin: 0;

  -webkit-box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.44);
}

.modalOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
      to bottom right,
      rgba(105, 105, 105, 0.74),
      rgba(50, 184, 162, 0.247)
    ),
    url(../zentiaBackground.png);
    min-width: 100%;
    min-height: auto;
}

hr {
  margin-top: 36px;
  margin-left: -20px;
  margin-right: -20px;
}

.welcomeImage {
  content: url(../welcome.png);
  /* object-fit: cover; */
  width: 100%;
  height: auto;
}

.infoImage {
  padding-right: 8px;
  content: url(../info.png);
  vertical-align: middle;
}

.modal-button {
  transition: 0.5s;
  background-color: #fff;
  border: 2px solid #3cdbc0;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 16px;
  border-radius: 2px;
  height: 32px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  width: 30%;

  cursor: pointer;
}

.modal-button:hover {
  color: #fff;
  background-color: #3cdbc0;
}
.modal-button span {
  position: relative;
}
.modal-button:hover span::after {
  width: 100%;
  background-color: #101820;
  max-width: 10000px;
}
.modal-button span::after {
  content: "";
  background-color: #101820;
  width: 0;
  height: 2px;
  -webkit-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  position: absolute;
  bottom: -2px;
  left: 0;
}
.login {
  border: none;
}

.login::before {
  content: "» ";
  font-size: 18px;
  left: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.login:hover {
  color: #fff;
  background-color: #3cdbc0;
}

.modal-button.link span::after {
  position: absolute;
  content: "";
  height: 2px;
  background: #3cdbc0;
  bottom: -2px;
  left: -14px;
  width: 100%;
}
.modal-button.link:hover {
  color: #3cdbc0;
  background-color: transparent;
}
.modal-button.link:hover span::after {
  animation: linkHoverAnimate 1s ease-in-out;
  color: #3cdbc0;
}

.modal-button.link span:hover {
  color: #3cdbc0;
}

.modal-button.link span:after {
  display: inline-block;
  min-width: 132px;
  text-align: left;
}

@keyframes linkHoverAnimate {
  0% {
    width: 0%;
    min-width: 0;
  }
  100% {
    width: 100%;
  }
}
.modal-button.back {
  border: none;
  transform: scale(1);
}

.modal-button.back::before {
  display: inline-block;
  align-self: center;
  scale: -1 1;
  content: "\00A0 »";
  font-size: 18px;
  left: -14px;
}

.modal-button.back:hover {
  color: #fff;
  background-color: #3cdbc0;
}

.container {
  position: relative;
  text-align: left;
  line-height: 24px;
  cursor: pointer;
}

.modalText {
  cursor: pointer;
  position: absolute;
  bottom: 18px;
  font-size: 20px;
  left: 12px;
  color: white;
  font-weight: 700;
  filter: drop-shadow(2px 2px 0px #666);
}
.modal-field-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal-field-name {
  flex: 2;
  font-size: 18px;
}
.modal-field-input {
  border-style: solid;
  flex: 3;
  border-color: #000000;
  font-size: 18px;

  padding: 8px;
}
.modal-field-input:focus {
  outline: none !important;
  border-color: #3cdbc0;
}
.modal-click {
  color: #3cdbc0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
}
.hidden-field {
  visibility: hidden;
  pointer-events: none;
}

.ReactModalPortal {
  position: absolute;
  height: 1px;
  width: 1px;
}
