.config-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.config-card-container {
  margin: 5px 5px;
  width: 45%;
  text-align: center;
  border: 0.5px solid grey;
  align-content: center;
  transition: all 0.3s;
}
.config-card-container:hover {
  transform: scale(1.02);
  box-shadow: 4px 3px 15px -5px #000000;
}
.config-image {
  height: 100px;
  width: 100%;
}
.config-text {
  font-size: 18px;
}
